import React, { useState, useContext, useEffect } from "react";

import "./header.scss";
import { AppStateContext } from "../../providers/app.provider";
import { fetchOrganizationInfo, fetchUserInfo } from "../../services/auth";
import logo from "../../images/pedgog_logo.svg";
import { useLocation } from "react-router-dom";
const Header = ({ ud }) => {
  const [state, dispatch] = useContext(AppStateContext);
  const [user, setUser] = useState({});
  const [meta, setMeta] = useState({});
  const [code, setCode] = useState();
  const [org, setOrg] = useState({});
  const userNameStyles = state?.menu?.Reports?.[0]?.userNameStyles || {}
  console.log("state", state)
  const logout = () => {
    dispatch({ type: "loggedOut" });
  };

  const getUserInfo = () => {
    fetchUserInfo()
      .then(({ data }) => {
        setUser(data);
        setMeta(data.meta);
        setCode(data.accesscode);
        fetchOrganizationInfo(data.organizationid)
          .then(({ data }) => {
            setOrg(data);
          })
          .catch(() => { });
      })
      .catch(() => { });
  };

  useEffect(() => {
    getUserInfo();
  }, []);
  let heading = "";
  if (code == "BPUTH" || code == "BPUTP") {
    heading = Object.values(meta)?.[0]?.college_code?.split(/[,]+/);
  }

  return (
    <div style={{ display: ud ? 'none' : 'block' }} className="header container-fluid">
      <div className="row" style={{ justifyContent: 'space-between', padding: "0rem 1rem" }}>
        <div style={{ display: "flex", alignItems: 'center' }}>
          {/* <div className="col-12 col-md-10 left"> */}
          <a className="d-flex me-3 br-1" href="/">
            <img className="header-logo me-2" src={logo} alt="logo" />
            <h3 className="mb-0 pt-1 mt-auto mb-auto">Analytics Dashboard</h3>
          </a>
          <div
            className="d-flex"
            style={{ flexDirection: "column", padding: "0px 14px" }}
          >
            <h4 className="mb-0" style={{ fontSize: "1rem" }}>
              {/* {org?.organizationname} */}
              {code == "BPUTH" ? org?.organizationname : heading?.[0]}
            </h4>
            <h4
              className="mb-0"
              style={{ fontSize: code == "BPUTH" ? "1rem" : "0.8rem" }}
            >
              {`${user?.role && user.role[0]?.rolename.toUpperCase()}`}
            </h4>
          </div>
        </div>
        <div style={{ display: "flex", alignItems: "center", gap: '1rem' }}>
          <div style={{ ...userNameStyles }}>{user.fullname}</div>
          <span
            className="material-icons material-icons-outlined user-name font-20"
            onClick={logout}
          >
            power_settings_new
          </span>
        </div>
      </div>
    </div>
  );
};

export default Header;
